$dropdown-width:            rem(100px);
$dropdown-item-padding-v:   rem(4px);
$dropdown-item-padding-h:   rem(12px);

.dropdown {
  position: relative;
  display: inline;
  min-width: $dropdown-width;

  .dropdown-btn {
    display: inline-block;
    min-width: $dropdown-width - $dropdown-item-padding-h;
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    left: 0 - $dropdown-item-padding-h;
    background-color: $white;
    min-width: $dropdown-width;
    border: $base-border;
    z-index: 1;

    a,
    a:visited {
      padding: $dropdown-item-padding-v $dropdown-item-padding-h;
      transition: $base-transition;

      &:hover {
        background-color: darken($white, 10%);
      }
    }
  }

  &:hover,
  &.open {
    .dropdown-content {
      display: flex;
    }
  }
}
