.meet-the-team {
  padding-top: rem(10px);
  padding-bottom: 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .meet-the-team-heading {
    font-weight: 500;
    font-size: rem(62.5px);
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
  }

  .meet-the-team-subheading {
    font-weight: 900;
    font-size: rem(21px);
    line-height: rem(30px);
    text-align: center;
    max-width: rem(680px);
    margin-top: rem(10px);
  }

  .meet-the-team-paragraph {
    font-weight: 300;
    font-size: rem(21px);
    line-height: rem(30px);
    text-align: center;
    max-width: rem(880px);
    margin-top: rem(10px);
  }

  .team-slider {
    width: calc(100% + #{rem(5px)});
    margin: rem(30px) #{0 - rem(5px)} 0;

    .team-member {
      width: calc(33.3333vw + #{rem(5px)});
      padding: 0 rem(5px);

      .member-photo {
        @include full-background;
        height: rem(325px);

      }

      .member-details {
        background-color: $blue;
        color: $white;
        padding: rem(20px) rem(25px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: rem(132px);

        @include phone {
          padding: rem(15px) rem(20px);
          min-height: rem(150px);
        }

        .member-name {
          font-weight: 900;
          font-size: rem(16px);
          text-transform: uppercase;
          color: $white;
        }

        .member-title {
          font-weight: 300;
          font-size: rem(16px);
          text-transform: uppercase;
          max-width: rem(240px);

          @include phone {
            max-width: 100%;
            font-size: rem(14px);
          }

          flex: auto;
        }

        .member-email {
          font-weight: 900;
          font-size: rem(16px);
          text-transform: uppercase;
          color: $white;

          &:before {
            content: '> ';
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(68px);
      line-height: 1;
      cursor: pointer;
      z-index: 5;

      @include phone {
        font-size: rem(42px);
      }

      &:before {
        margin: 0;
        width: rem(39px);
        color: $white;
        opacity: 1;

        @include phone {
          width: rem(25px);
        }
      }

      &.icon-angle-left {
        left: rem(90px);

        @include phone {
          left: rem(65px);
        }
      }

      &.icon-angle-right {
        right: rem(90px);

        @include phone {
          right: rem(65px);
        }
      }
    }
  }
}
