.full-image-block-text {
  padding: rem(85px) 0 rem(75px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include full-background;

  @include phone {
    padding: 0;
    background: none!important;
  }

  .block-text {
    width: 50vw;
    background: $blue-purple;
    color: $white;
    display: flex;

    @include tablet-only {
      width: 66.66vw;
    }

    @include phone {
      width: 100vw;
    }

    &.left {
      justify-content: flex-end;

      .block-text-container {
        padding: rem(55px) rem(55px) rem(70px) 0;

        @include container {
          padding-left: $container-padding-h;
        }

        @include phone {
          padding: rem(35px) $container-padding-h;
        }

        .block-text-link {
          left: 0;
        }
      }
    }

    &.right {
      margin-left: auto;
      justify-content: flex-start;

      .block-text-container {
        padding: rem(55px) 0 rem(70px) rem(55px);

        @include container {
          padding-right: $container-padding-h;
        }

        @include phone {
          padding: rem(35px) $container-padding-h;
        }

        .block-text-link {
          right: 0;
        }
      }
    }

    .block-text-container {
      width: rem($container-max-width-px / 2);
      position: relative;

      .block-text-heading {
        font-family: $font-quanta-east;
        font-size: rem(54px);
        font-weight: normal;
        line-height: 1.3;
        color: $white;
        margin-bottom: rem(40px);

        @include lg-tablet-max {
          font-size: rem(36px);
        }

        @include phone {
          font-size: rem(28px);
        }
      }

      .block-text-subheading {
        font-size: rem(21px);
        line-height: rem(35px);
        color: $white;

        @include phone {
          font-size: rem(16px);
          line-height: 1.3;
        }
      }

      .block-text-paragraph {
        font-weight: 300;
        font-size: rem(23px);
        line-height: rem(35px);

        @include phone {
          font-size: rem(17px);
          line-height: 1.3;
        }

        p {
          margin: 0;
        }
      }

      .block-text-link {
        @include tablet {
          position: absolute;
          bottom: 0 - rem(27px);
        }
      }
    }
  }
}
