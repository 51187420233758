.header-image-gradient-border {
  width: 100%;
  height: rem(600px);
  padding: 0 0 rem(9px);
  position: relative;
  @include full-background;

  @include phone {
    height: 50vw;
  }

  &:after {
    @include pseudo;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem(9px);
    background: $purple-blue;
  }
}
