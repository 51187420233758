.sixth-form-application {
  padding-top: 0;
  padding-bottom: rem(50px);

  .section-heading {
    font-weight: 500;
    font-size: rem(22px);
    line-height: rem(33px);
    position: relative;
    padding-bottom: rem(7px);
    margin-top: rem(50px);
    margin-bottom: rem(20px);

    &:after {
      @include pseudo;
      left: $grid-gutter;
      right: $grid-gutter;
      bottom: 0;
      height: rem(7px);
      background: $purple-dark-blue;
    }
  }
}
