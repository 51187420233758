.partners {
  padding-top: rem(60px);
  padding-bottom: rem(60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .partners-heading {
    font-family: $font-museo-sans;
    font-weight: 500;
    font-size: rem(31px);
    text-transform: uppercase;
    text-align: center;

    @include phone {
      font-size: rem(26px);
    }
  }

  .partners-subheading {
    font-size: rem(21px);
    font-weight: 900;
    text-align: center;
    max-width: rem(840px);
    color: $dark-blue;
    margin-top: rem(5px);

    @include phone {
      font-size: rem(16px);
    }
  }

  .partners-paragraph {
    font-size: rem(21px);
    font-weight: 300;
    text-align: center;
    max-width: rem(840px);

    @include phone {
      font-size: rem(16px);
    }
  }

  .partners-slider-heading {
    font-weight: 500;
    font-size: rem(25px);
    line-height: 1;
    text-transform: uppercase;
    margin-top: rem(55px);
  }

  .partners-slider-wrapper {
    position: relative;
    width: 100%;

    .partners-slider {
      display: block;
      width: calc(100% - #{rem($container-max-width-px - $tablet-px)});
      max-width: rem($tablet-px);
      margin: rem(20px) auto;

      @include container {
        width: calc(100% - #{rem(200px)});
        max-width: calc(100% - #{rem(200px)});
      }

      .partner-slide {
        height: rem(71px);
        display: inline-flex!important;
        align-items: center;
        justify-content: center;
        padding: 0 rem(20px);

        img {
          max-height: rem(71px);
          width: auto;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: rem(71px);
      line-height: 1;
      cursor: pointer;
      z-index: 5;

      &:before {
        margin: 0;
        width: rem(42px);
      }

      &.icon-angle-left {
        left: 0;

        // @include container {
        //   left: calc(#{$container-padding-h} - (100vw - #{rem($tablet-px)}) / 2);
        // }

        // @include tablet-max {
        //   left: $container-padding-h;
        // }
      }

      &.icon-angle-right {
        right: 0;

        // @include container {
        //   right: calc(#{$container-padding-h} - (100vw - #{rem($tablet-px)}) / 2);
        // }

        // @include tablet-max {
        //   right: $container-padding-h;
        // }
      }
    }
  }
}
