.two-images {

  @include phone {
    padding-top: 0;
    padding-bottom: 0;

    .two-images-image {
      flex-basis: 100%;
      max-width: 100%;
      padding-top: rem(10px);
      padding-bottom: rem(10px);
    }
  }
}
