.pagination {
  display: flex;
  margin: rem(46px) auto;
  align-items: center;
  justify-content: center;

  .first,
  .next,
  .num,
  .current,
  .prev,
  .last {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 rem(4px);
    // border: rem(1px) solid $light-grey;
    width: rem(42px);
    height: rem(42px);
    font-size: rem(21px);
    line-height: 1;
    padding: 0;
    transition: $base-transition;
    color: $grey;

    // &:hover,
    // &:active {
    //   background-color: lighten($black, 10%);
    // }
  }

  .first,
  .prev,
  .next,
  .last {
    background-color: $black;
    color: $white;
    font-size: rem(16px);
  }

  .first,
  .prev {

    &:hover,
    &:active {
      padding-right: rem(10px);
    }
  }

  .next,
  .last {

    &:hover,
    &:active {
      padding-left: rem(10px);
    }
  }

  .current {
    color: $purple;
  }

  .dots {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 rem(6px);
    font-size: rem(21px);
    line-height: 1;
    letter-spacing: rem(1.5px);
    padding-left: rem(1.5px);
    color: $grey;
  }
}
