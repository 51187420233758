.news-listing {


  .article {
    margin-bottom: rem(55px);

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7) {
      @extend %col-6;

      @include phone {
        flex-basis: 100%!important;
        max-width: 100%!important;
      }

      .article-heading,
      .article-paragraph {
        max-width: rem(360px);

        @include phone {
          max-width: 100%;
        }
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      @extend %col-4;

      @include tablet-max {
        flex-basis: 50%!important;
        max-width: 50%!important;
      }

      @include phone {
        flex-basis: 100%!important;
        max-width: 100%!important;
      }
    }

    .article-image {
      @include full-background;
      @include responsive-ratio(1, 1);
    }

    .article-heading {
      font-weight: 500;
      font-size: rem(27px);
      line-height: rem(34px);
      text-transform: uppercase;
      margin-top: rem(30px);

      @include phone {
        font-size: rem(18px);
        line-height: 1.7;
      }
    }

    .article-paragraph {
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(30px);
      margin-top: rem(10px);
      // margin-bottom: rem(16px);

      @include phone {
        font-size: rem(16px);
        line-height: 1.4;
      }
    }

    // .article-link,
    // .article-link:visited {
    //   font-weight: 500;
    //   font-size: rem(18px);
    //   line-height: 1;
    //   text-transform: uppercase;
    //   color: $primary-font-color;
    //
    //   @include phone {
    //     font-size: rem(16px);
    //   }
    //
    //   &:before {
    //     content: '> ';
    //   }
    //
    //   &:hover {
    //     color: lighten($primary-font-color, 10%);
    //   }
    // }
  }
}
