nav {
  background-color: $white;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: $nav-bar-height;
  border-bottom: rem(3px) solid $black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: $base-transition;

  @include phone {
    background-color: $black;
  }

  &:after {
    @include pseudo;
    top: $nav-bar-toggle-width;
    left: 0;
    right: 0;
    height: rem(3px);
    background-color: $black;
    z-index: 10;
  }

  &.nav-up {
    position: fixed;
    top: 0 - $nav-bar-height;
  }

  &.nav-down {
    position: fixed;
    top: 0;
  }

  .nav-home-link {
    z-index: 110;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(35px) 0 rem(15px);
    font-family: $font-quanta-east;
    font-weight: normal;
    background-color: $black;
    color: $white;
    font-size: rem(22px);
    line-height: 0.9;

    .utc {
      font-size: rem(30px);
    }
  }

  .nav-toggle {
    // position: absolute;
    // right: rem(20px);
    z-index: 10;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $nav-bar-toggle-width;
    // height: rem(26px);

    .nav-toggle-icon {
      position: relative;
      display: inline-block;
      width: rem(36px);
      height: rem(4px);
      background-color: $black;
      transition: $base-transition;

      &:before,
      &:after {
        @include pseudo;
        left: 0;
        width: rem(36px);
        height: rem(4px);
        background-color: $black;
        transition: $base-transition;
        z-index: 1;
      }

      &:before {
        top: rem(-10px);
      }

      &:after {
        bottom: rem(-10px);
      }
    }
  }

  .nav-left {
    padding-left: rem(10px);

    @include tablet-max {
      display: none!important;
    }

    .nav-link {
      position: relative;

      &:after {
        @include pseudo;
        bottom: 0;
        left: rem(20px);
        right: rem(20px);
        height: rem(10px);
        background-color: $purple;
        opacity: 0;
        transition: $base-transition;
      }

      &.active,
      &:hover,
      &:focus {

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .nav-right {
    margin-left: auto;

    @include phone {
      display: none!important;
    }
  }

  .nav-left,
  .nav-right {
    background-color: $white;
    // position: fixed;
    // padding-top: $nav-bar-height;
    // top: 0;
    // right: 0 - $nav-menu-width;
    // bottom: 0;
    // width: $nav-menu-width;
    transition: $base-transition;
    z-index: 5;
    display: inline-flex;
    align-items: stretch;
    justify-content: center;

    @include phone {
      right: -100vw;
      width: 100vw;
    }

    .nav-link,
    .nav-link:visited {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-museo-sans-condensed;
      font-size: rem(16px);
      line-height: 1;
      text-transform: uppercase;
      color: $black;
      padding: 0 rem(20px);

      &:hover,
      &:focus {
        color: lighten($dark-grey, 10%);
      }
    }

    .nav-search-toggle {
      width: $nav-bar-toggle-width;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-search {
        font-size: rem(26px);
      }
    }

    .nav-apply {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-museo-sans;
      font-size: rem(18px);
      font-weight: 900;
      text-transform: uppercase;
      background-color: $purple;
      color: $white;
      padding: 0 rem(18px) 0 rem(28px);

    }
  }

  .nav-dropdown {
    position: fixed;
    top: calc(-100vh - #{$nav-bar-height});
    left: 0;
    right: 0;
    height: calc(100vh - #{$nav-bar-height});
    transition: $base-transition;
    background-color: $white;
    padding: rem(40px) rem(34px);
    overflow-y: auto;

    @include tablet {
      padding: rem(50px) rem(44px);
    }

    @include lg-tablet {
      padding: rem(70px) rem(84px) rem(70px) rem(54px);
    }

    > .row {
      @include lg-tablet-max {
        flex-wrap: wrap;
        &::after {
          content: '';
          flex: auto;
        }
      }
    }

    .col {
      padding-top: rem(50px);
      display: flex;
      flex-direction: column;

    }

    .menu-title {
      font-weight: 900;
      font-size: rem(20px);
      text-transform: uppercase;
      margin-bottom: rem(44px);
    }

    .menu {
      @include lg-tablet-max {
        flex-basis: 50%;
        max-width: 50%;
      }

      .nav-menu-link {
        font-weight: 300;
        font-size: rem(25px);
        margin-bottom: rem(22px);

        @include phone {
          font-size: rem(18px);
          margin-bottom: rem(12px);
        }

        &.hover {

        }
      }
    }

    .blog {
      @include laptop {
        margin-right: 10%;
      }

      @include lg-tablet-max {
        flex-basis: 50%;
        max-width: 50%;
      }

      @include phone {
        flex-basis: 100%;
        max-width: 100%;
      }

      .nav-news-item {
        margin-bottom: rem(20px);
        max-width: rem(350px);

        &.hover {

        }

        .nav-news-description {
          font-weight: 300;
          font-size: rem(20px);
          line-height: rem(22px);
          margin-bottom: 0;
        }

        .nav-news-date {
          font-weight: 300;
          font-size: rem(10px);
          line-height: rem(22px);
        }
      }

      .nav-news-more {
        font-weight: 900;
        font-size: rem(16px);
        text-transform: uppercase;

        &:after {
          content: ' >';
        }
      }
    }

    .contact {

      @include lg-tablet-max {
        flex-basis: 50%;
        max-width: 50%;
      }

      @include phone {
        flex-basis: 100%;
        max-width: 100%;
      }

      .nav-heading {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-bottom: 0;
      }

      .nav-address {
        font-weight: 300;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-bottom: 0;
      }

      .nav-subheading {
        font-weight: 300;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-top: rem(22px);
        margin-bottom: 0;
      }

      .nav-contact-link,
      .nav-contact-link:visited {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(22px);
        color: $dark-blue;
        margin-bottom: 0;

        &.hover {

        }
      }

      .nav-cta {
        margin-top: auto;

        @include phone {
          margin-top: rem(36px);
        }

        .nav-cta-link,
        .nav-cta-link:visited {
          display: block;
          height: rem(54px);
          line-height: rem(54px);
          background-color: $purple;
          padding: 0 rem(22px) 0 rem(22px);
          font-weight: 900;
          font-size: rem(18px);
          text-transform: uppercase;
          color: $white;
          margin-bottom: rem(10px);
          position: relative;
          max-width: rem(350px);

          &:hover {
            background-color: lighten($purple, 10%);
            color: $white;

            .icon-angle-right {
              right: rem(17px);
            }
          }

          &.blue {
            background-color: $blue;

            &:hover {
              background-color: lighten($blue, 10%);
            }
          }

          .icon-angle-right {
            position: absolute;
            top: 0;
            right: rem(22px);
            font-size: rem(12px);
            transition: $base-transition;
          }
        }

        .nav-social {
          margin-top: rem(33px);
          .nav-social-link,
          .nav-social-link:visited {
            border-radius: 50%;
            background-color: $black;
            width: rem(40px);
            height: rem(40px);
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:not(:last-child) {
              margin-right: rem(32px);

              @include laptop {
                margin-right: rem(17px);
              }

              @include desktop {
                margin-right: rem(32px);
              }
            }

            &:hover {
              background-color: lighten($black, 10%);
            }

            [class^="icon-"]:before,
            [class*=" icon-"]:before {
              color: $white;
              font-size: rem(26px);
            }
          }
        }
      }
    }
  }
}

body {
  &.nav-open {
    .nav-dropdown {
      top: $nav-bar-height;
    }

    .nav-toggle {
      .nav-toggle-icon {
        background-color: $white;

        &:before {
          top: 0;
          transform: rotate(-45deg);
        }

        &:after {
          bottom: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
