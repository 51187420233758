.header-open-events-text {
  position: relative;
  background: $blue-purple;
  padding-top: rem(65px);
  padding-bottom: rem(50px);
  color: $white;

  .hashtag {
    position: absolute;
    display: flex;
    align-items: center;
    background: $blue-purple;
    bottom: 0 - rem(27px);
    right: 0;
    height: rem(54px);
    padding: 0 rem(80px) 0 rem(20px);
    font-weight: 900;
    font-size: rem(34px);
    text-transform: uppercase;
    color: $white;

    @include phone {
      bottom: 0 - rem(17px);
      height: rem(34px);
      padding: 0 rem(20px);
      font-size: rem(18px);
    }
  }

  .header-open-events-header {
    display: inline-block;
    font-weight: 900;
    font-size: rem(31px);
    line-height: 1;
    color: $white;
    padding-bottom: rem(19px);
    position: relative;

    @include phone {
      font-size: rem(26px);
    }

    &:after {
      @include pseudo;
      bottom: 0;
      left: 0;
      right: 0;
      height: rem(9px);
      background: $purple-blue;
    }
  }

  .header-open-events-page-title {
    font-family: $font-quanta-east;
    font-weight: normal;
    font-size: rem(60px);
    line-height: rem(73px);
    max-width: rem(960px);
    margin-top: rem(40px);

    @include phone {
      font-size: rem(36px);
      line-height: 1.3;
    }
  }

  .header-open-events-subheading {
    font-weight: 900;
    font-size: rem(25px);
    line-height: rem(28px);
    max-width: rem(660px);
    margin-top: rem(40px);

    @include phone {
      font-size: rem(20px);
      line-height: 1.3;
    }
  }
}
