.text-blocks-promo {
  padding: 0 0 rem(9px);
  background: $blue-dark-blue-solid;
  position: relative;

  &:after {
    @include pseudo;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem(9px);
    background: $purple-blue;
  }

  .text-blocks-wrapper {
    @include tablet-max {
      padding: 0;
    }
  }

  .text-block-promo {
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include tablet-max {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.blue {
      background-color: $blue;
      padding: rem(45px) rem(50px) rem(45px) 0;

      @include tablet-max {
        padding: rem(45px) rem(40px);
      }

      .text-block-promo-heading {
        margin-left: 0 - rem(65px);
        padding-left: rem(65px);

        @include tablet-max {
          margin-left: 0 - rem(40px);
        }
      }
    }

    &.dark-blue {
      background-color: $dark-blue;
      padding: rem(45px) 0 rem(45px) rem(65px);

      @include tablet-max {
        padding: rem(45px) rem(40px);
      }

      .text-block-promo-heading {
        margin-left: 0 - rem(65px);
        margin-right: 0 - rem(65px);
        padding-left: rem(65px);

        @include tablet-max {
          margin-left: 0 - rem(40px);
        }
      }
    }

    .text-block-promo-heading {
      background: $purple-blue-promo;
      font-weight: 900;
      font-size: rem(29px);
      height: rem(54px);
      line-height: rem(54px);
      text-transform: uppercase;
      color: $white;
      margin-bottom: rem(40px);
      width: calc(100% + #{rem(65px)});

      @include phone {
        font-size: rem(16px);
        height: rem(40px);
        line-height: rem(40px);
      }
    }

    .text-block-heading {
      font-family: $font-quanta-east;
      font-size: rem(54px);
      font-weight: normal;
      line-height: 1.3;
      color: $white;
      margin-bottom: rem(40px);
      flex: auto;

      @include phone {
        font-size: rem(32px);
      }
    }

    .text-block-paragraph {
      font-weight: 300;
      font-size: rem(23px);
      line-height: rem(35px);
      color: $white;

      @include phone {
        font-size: rem(16px);
        line-height: 1.4;
      }
    }

    .text-block-promo-link {
      margin-top: rem(46px);
    }
  }
}
