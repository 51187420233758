.book-a-tour-form {
  position: fixed;
  top: $nav-bar-height;
  right: -100vw;
  bottom: 0;
  width: 100vw;
  background-color: $white;
  transition: $base-transition;
  z-index: 50;
  overflow-y: auto;
  padding-top: rem(60px);

  @include lg-tablet-max {
    padding-top: rem(80px);
  }

  .full-container {
    position: relative;
  }

  .btn-close {
    position: absolute;
    top: rem(20px);
    left: rem(60px);
    width: rem(36px);
    height: rem(4px);

    @include lg-tablet-max {
      top: rem(-40px);
      left: rem(20px);
    }

    &:before,
    &:after {
      @include pseudo;
      left: 0;
      width: rem(36px);
      height: rem(4px);
      background-color: $black;
      transition: $base-transition;
      z-index: 1;
    }

    &:before {
      top: 0;
      transform: rotate(-45deg);
    }

    &:after {
      bottom: 0;
      transform: rotate(45deg);
    }
  }

  .form-heading {
    background: $purple-dark-blue;
    height: rem(54px);
    line-height: rem(54px);
    padding: 0 rem(16px);
    font-weight: 900;
    font-size: rem(29px);
    text-transform: uppercase;
    color: $white;
    margin-bottom: rem(20px);

    @include phone {
      height: rem(34px);
      line-height: rem(34px);
      padding: 0 rem(10px);
      font-size: rem(16px);
    }
  }
}

body {

  &.nav-up {

    .book-a-tour-form {
      top: 0;
    }
  }

  &.book-a-tour-open {

    .book-a-tour-form {
      right: 0;
    }
  }
}
