.policies.container {
  padding-top: rem(100px);
  padding-bottom: rem(70px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include phone {
    padding-top: rem(60px);
    padding-bottom: rem(30px);
  }

  .policies-heading {
    font-weight: 500;
    font-size: rem(31px);
    line-height: 1;
    text-align: center;

    @include phone {
      font-size: rem(22px);
    }
  }

  .policies-subheading {
    font-weight: 300;
    font-size: rem(21px);
    line-height: rem(30px);
    text-align: center;

    @include phone {
      font-size: rem(18px);
      line-height: 1.4;
    }
  }

  .policy-group {
    width: 100%;

    .policy-heading {
      margin-top: rem(40px);
      margin-bottom: 0;
      height: rem(54px);
      line-height: rem(54px);
      background-color: $blue;
      color: $white;
      padding-left: rem(24px);
      font-weight: 900;
      font-size: rem(18px);
      text-transform: uppercase;

      @include phone {
        height: rem(34px);
        line-height: rem(34px);
        // font-weight: 500;
        font-size: rem(16px);
        padding-left: rem(12px);
      }
    }

    .policy-rows {
      margin: 0 (($grid-gutter / 2) * -1);

      .policy-doc {
        margin-top: $grid-gutter;
        padding: 0 ($grid-gutter / 2);

        @include phone {
          flex-basis: 100%;
          max-width: 100%;
        }

        .policy-doc-link {
          display: block;
          height: rem(54px);
          line-height: rem(54px);
          background-color: $pale-blue;
          color: $white;
          padding-left: rem(24px);
          font-weight: 900;
          font-size: rem(18px);
          text-transform: uppercase;

          @include phone {
            height: rem(34px);
            line-height: rem(34px);
            // font-weight: 500;
            font-size: rem(16px);
            padding-left: rem(12px);
          }

          &:before {
            content: '> ';
          }

          &:hover {
            background-color: darken($pale-blue, 10%);
          }
        }
      }
    }
  }
}
