.women-in-stem {
  padding: 0;

  .image-with-heading {
    position: relative;
    @include full-background;
    height: rem(475px);

    .image-heading-container {
      position: absolute;
      bottom: 0 - rem(55px);
      left: 0;
      right: 0;

      .image-heading-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(110px);

        @include tablet-max {
          height: rem(90px);
        }

        &:before {
          @include pseudo;
          left: 0;
          top: 0;
          bottom: 0;
          width: calc(#{$container-max-width} + ((100vw - #{$container-max-width}) / 2));
          background: $blue-purple;

          @include container {
            width: 100vw;
          }
        }
      }

      .image-heading {
        font-family: $font-quanta-east;
        font-weight: normal;
        font-size: rem(62.5px);
        line-height: 1.2;
        text-align: center;
        color: $white;
        margin: 0;


        @include tablet-max {
          font-size: rem(38px);
        }

        @include phone {
          font-size: rem(36px);
        }
      }
    }
  }

  .blue-block {
    padding-top: rem(110px);
    padding-bottom: rem(60px);
    background-color: $blue;
    color: $white;

    @include phone {
      padding-top: rem(80px);
      padding-bottom: rem(30px);
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .image-subheading {
      font-weight: 500;
      font-size: rem(31px);
      line-height: rem(35px);
      text-align: center;
      color: $white;

      @include phone {
        font-size: rem(26px);
        line-height: 1.4;
      }
    }

    .image-paragraphs {
      p {
        max-width: rem(900px);
        margin-top: rem(20px);
        font-weight: 300;
        font-size: rem(22.5px);
        line-height: rem(35px);
        text-align: center;

        @include phone {
          font-size: rem(16px);
          line-height: 1.4;
        }
      }
    }
  }

  .women-in-stem-quote-container {
    background-color: $light-blue;
    color: $white;
    padding-top: rem(60px);
    padding-bottom: rem(65px);
    position: relative;

    @include phone {
      padding-top: rem(40px);
      padding-bottom: rem(45px);
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:after {
      @include pseudo;
      bottom: 0;
      left: 0;
      right: 0;
      height: rem(9px);
      background: $purple-blue;
    }

    .women-in-stem-quote {
      max-width: rem(900px);
      font-weight: 500;
      font-size: rem(31px);
      line-height: rem(45.5px);
      text-align: center;
      color: $white;

      @include phone {
        font-size: rem(22px);
        line-height: 1.3;
      }
    }

    .women-in-stem-quote-name {
      max-width: rem(900px);
      font-weight: 300;
      font-size: rem(22.5px);
      line-height: rem(35px);
      text-align: center;

      @include phone {
        font-size: rem(16px);
        line-height: 1.3;
      }
    }

    .women-in-stem-links {

      @include phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .section-link-right {
        @include tablet {
          margin-left: rem(64px);
        }
      }
    }
  }
}
