.contact-form {
  padding-bottom: rem(60px);

  .contact-form-heading {
    background: $purple-dark-blue;
    height: rem(54px);
    line-height: rem(54px);
    padding: 0 rem(16px);
    font-weight: 900;
    font-size: rem(29px);
    text-transform: uppercase;
    color: $white;
    margin-bottom: rem(20px);

    @include phone {
      height: rem(34px);
      line-height: rem(34px);
      padding: 0 rem(10px);
      font-size: rem(16px);
    }
  }
}
