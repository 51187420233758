.text {
  padding: rem(30px) #{$container-padding-h + rem(40px)} rem(40px);

  @include phone {
    padding: rem(16px) #{$container-padding-h} rem(30px);
  }

  .text-heading {
    font-weight: 500;
    font-size: rem(31px);
    line-height: 1;
    text-transform: uppercase;
    margin-top: rem(30px);

    @include phone {
      font-size: rem(18px);
    }
  }

  hr {
    margin: rem(10px) auto rem(50px) 0;

    @include phone {
      margin: rem(10px) auto rem(40px) 0;
    }
  }

  .text-paragraphs {

    p {
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(30px);
      margin-top: rem(16px);

      @include phone {
        font-size: rem(16px);
        line-height: 1.4;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-top: rem(16px);
      margin-bottom: rem(32px);

      li {
        font-weight: 300;
        font-size: rem(21px);
        line-height: 30px;

        @include phone {
          font-size: rem(16px);
          line-height: 1.4;
        }
      }
    }
  }
}
