.header-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header-article-heading {
    font-weight: 500;
    font-size: rem(31px);
    color: $primary-color;
    margin-top: rem(46px);
    margin-bottom: rem(46px);
    width: 100%;
    max-width: rem(855px);
  }

  .header-article-author {
    font-weight: 300;
    font-size: rem(21px);
    width: 100%;
    max-width: rem(855px);
    // text-align: center;
  }
}
