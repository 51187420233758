.news-featured {
  padding-bottom: rem(60px);

  .featured-container {
    position: relative;
    padding-bottom: rem(9px);

    &:hover {
      .btn-link {
        background-color: lighten($purple, 10%);
        color: $white;
      }
    }

    &:after {
      @include pseudo;
      bottom: 0;
      left: 0;
      right: 0;
      height: rem(9px);
      background: $purple-blue;
    }
  }

  .featured-image {
    @include full-background;
    @include responsive-ratio(2, 1);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .featured-text {
    background-color: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    padding: rem(130px) rem(70px) rem(20px);

    @include phone {
      padding: rem(100px) rem(20px) rem(20px);
      flex-basis: 100%;
      max-width: 100%;
    }

    .featured-banner {
      position: absolute;
      top: rem(50px);
      left: 0;
      right: rem(60px);
      height: rem(54px);
      line-height: rem(54px);
      padding-left: rem(36px);
      font-family: $font-quanta-east;
      font-weight: normal;
      font-size: rem(21px);
      background: $purple-dark-blue;
      color: $white;

      @include phone {
        top: rem(30px);
        height: rem(44px);
        line-height: rem(44px);
        font-size: rem(18px);
      }
    }

    .featured-heading {
      font-weight: 500;
      font-size: rem(32px);
      line-height: rem(48px);
      text-transform: uppercase;
      color: $white;
      margin-bottom: rem(36px);
      text-align: center;

      @include phone {
        font-size: rem(22px);
        margin-bottom: rem(26px);
      }
    }

    .featured-description {
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(30px);
      text-align: center;
      margin-bottom: rem(20px);

      @include phone {
        font-size: rem(16px);
        line-height: 1.3;
      }
    }
  }
}
