.paragraphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: rem(46px);

  @include phone {
    padding-bottom: rem(26px);
  }

  p {
    font-weight: 300;
    font-size: rem(21px);
    line-height: 1.4;
    //text-align: center;
    max-width: rem(855px);
    margin-top: rem(16px);

    @include phone {
      font-size: rem(16px);
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-top: rem(16px);
    margin-bottom: rem(32px);

    li {
      font-weight: 300;
      font-size: rem(21px);
      line-height: 1.4;

      @include phone {
        font-size: rem(16px);
      }
    }
  }
}
