.header-image {
  height: rem(600px);
  @include full-background;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include phone {
    max-height: calc(69vw - #{$nav-bar-height});
  }

  .header-image-text {
    height: rem(150px);
    background: linear-gradient(to bottom,  rgba($black, 0), rgba($black,0.70));
    color: $white;

    @include phone {
      height: rem(130px);
    }

    .header-image-heading {
      margin: rem(16px) 0 0;
      font-size: rem(50px);
      line-height: 1;
      font-weight: 900;
      color: $white;

      @include phone {
        font-size: rem(46px);
      }
    }

    .header-image-subheading {
      margin: rem(10px) 0 0;
      font-size: rem(24px);
      line-height: 1;
      font-style: italic;

      @include phone {
        font-size: rem(20px);
      }
    }
  }
}
