.newsletter-subscribe {
  padding-top: rem(45px);
  padding-top: rem(45px);
  color: $white;

  &.blue {
    background-color: $blue-accent;
  }

  &.purple {
    background-color: $purple;
  }

  .col-text {

    @include tablet-max {
      flex-basis: 45%;
      max-width: 45%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .col-form {

    @include tablet-max {
      flex-basis: 55%;
      max-width: 55%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .newsletter-subscribe-heading {
    font-weight: 900;
    font-size: rem(34px);
    line-height: 1;
    color: $white;
    text-transform: uppercase;
  }

  .newsletter-subscribe-paragraph {
    font-weight: 100;
    font-size: rem(16px);
    line-height: 1.2;
  }

  .subscribe-response {
    font-weight: 500;
    font-size: rem(16px);
    line-height: 1.2;
  }

  .subscribe-fields {
    width: 100%;
    display: flex;
    align-items: center;

    @include phone {
      flex-direction: column;
    }

    .email-subscribe {
      font-weight: 100;
      font-size: rem(16.5px);
      background-color: transparent;
      border-bottom: rem(1px) solid $white;
      color: $white;
      padding-left: 0;

      @include placeholder {
        font-weight: 100;
        font-size: rem(16.5px);
        color: $white;
      }

      @include phone {
        margin-top: rem(16px);
        width: calc(100% - #{rem(30px)});
      }
    }

    .btn-subscribe {
      font-size: rem(23.5px);
      margin-top: 0;
      margin-left: rem(20px);

      @include phone {
        margin-left: 0;
        margin-top: rem(16px);
      }

      &.blue {
        background-color: $blue-accent;

        &:hover {
          background-color: lighten($blue-accent, 10%);
        }
      }
    }
  }

}
