.header-filter {
  padding-top: rem(60px);
  padding-bottom: rem(60px);
  font-weight: 500;
  font-size: rem(21px);
  line-height: rem(30px);
  color: $grey;

  .dropdown {
    height: rem(30px);
  }

  .news-filter-value {
    font-weight: 900;
    font-size: rem(21px);
    line-height: rem(30px);
  }
}
