// Shades
// ==================================================

$black:           #000000                   !default;
$off-black:       #212121                   !default;
$dark-grey:       #424242                   !default;
$grey:            #9e9e9e                   !default;
$light-grey:      #e0e0e0                   !default;
$off-white:       #fafafa                   !default;
$white:           #ffffff                   !default;


// Colours
// ==================================================
$red:             #f44336                   !default;
$pink:            #e91e63                   !default;
$purple:          #9c27b0                   !default;
$deep-purple:     #673ab7                   !default;
$indigo:          #3f51b5                   !default;
$blue:            #2196f3                   !default;
$light-blue:      #03a9f4                   !default;
$cyan:            #00bcd4                   !default;
$teal:            #009688                   !default;
$green:           #4caf50                   !default;
$light-green:     #8bc34a                   !default;
$lime:            #cddc39                   !default;
$yellow:          #ffeb3b                   !default;
$amber:           #ffc107                   !default;
$orange:          #ff9800                   !default;
$deep-orange:     #ff5722                   !default;
$brown:           #795548                   !default;
$blue-grey:       #607d8b                   !default;

// Status Colours
// ==================================================
$color-info:      $blue                     !default;
$color-error:     $red                      !default;
$color-success:   $green                    !default;

// Social Colours
// ==================================================
$color-facebook:  #4267b2                   !default;
$color-linkedin:  #2867b2                   !default;
$color-pinterest: #bd081c                   !default;
$color-skype:     #00aff0                   !default;
$color-twitter:   #1da1f2                   !default;
$color-whatsapp:  #4ac959                   !default;
$color-youtube:   #f00                      !default;

// Defaults
// ==================================================

$background-color:      $white              !default;
$primary-color:         $black              !default;
$secondary-color:       $off-black          !default;
$overlay-color:         rgba($black, 0.5)   !default;
$dark-overlay-color:    rgba($black, 0.75)  !default;
$gradient-overlay:      linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.8) 100%)  !default;
$dark-gradient-overlay: linear-gradient(180deg, rgba(0,0,0,0.5) 0%,  rgba(0,0,0,0.8) 100%)  !default;
$dark-color:            $off-black          !default;
$light-color:           $off-white          !default;


// Base
// ==================================================

// Typography
$base-font-size: 16px                       !default;

// Border
$base-border-radius:  rem(4px)              !default;
$base-border-size:    rem(1px)              !default;
$base-border-style:   solid                 !default;
$base-border-color:   $black                !default;
$base-border: $base-border-size $base-border-style $base-border-color !default;

// Spacing
$base-spacing:        rem(10px)             !default;
$base-small-spacing:  $base-spacing / 2     !default;

// Focus
$focus-type:           'box-shadow'         !default;
$focus-outline-width:  rem(2px)             !default;
$focus-outline-style:  solid                !default;
$focus-outline-color:  rgba($off-black, .5) !default;
$focus-outline:        $focus-outline-width $focus-outline-style $focus-outline-color !default;
$focus-outline-offset: 0                    !default;
$focus-box-shadow:     0px 0px 2px 2px rgba($black, .5) !default;
$focus-box-shadow-inset: inset $focus-box-shadow  !default;

// Animations
$base-duration:   .4s                       !default;
$base-timing:     ease                      !default;
$base-transition: all $base-duration $base-timing !default;

// Padding
$base-padding: rem(8px)                     !default;


// Typography
// ==================================================

// Primary (Default)
$primary-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$primary-font-color:  $black                !default;
$primary-font-weight: 400                   !default;
$primary-line-height: 1.2                   !default;
$primary-font-margin: 0 0 $base-small-spacing !default;

// Secondary
$secondary-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$secondary-font-color:  $off-black          !default;
$secondary-font-weight: 400                 !default;
$secondary-line-height: 1.2                 !default;
$secondary-font-margin: 0 0 $base-small-spacing !default;

// Heading
$heading-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$heading-font-color:  $black                !default;
$heading-font-weight: 700                   !default;
$heading-line-height: 1.2                   !default;
$heading-font-margin: 0 0 $base-small-spacing !default;
$h1-size: 2.5rem                            !default;
$h2-size: 2rem                              !default;
$h3-size: 1.75rem                           !default;
$h4-size: 1.5rem                            !default;
$h5-size: 1.25                              !default;
$h6-size: 1rem                              !default;

$small-font-size: .75rem                    !default;

// Links
$link-font-family:     $primary-font-family !default;
$link-font-color:      darken($blue, 30%)   !default;
$link-line-height:     $primary-line-height !default;
$link-font-margin:     0                    !default;
$link-font-color-hover: darken($link-font-color, 50%) !default;

// Flex & Grid
// ==================================================
$grid-cols:   12                            !default;
$grid-rows:   3                             !default;
$grid-gutter: $base-spacing                 !default;

// Responsiveness
// ==================================================
$phone-px:     0px                          !default;
$tablet-px:    768px                        !default;
$lg-tablet-px: 992px                        !default;
$laptop-px:    1200px                       !default;
$desktop-px:   1600px                       !default;

// Container
// ==================================================
$container-max-width-xs-px: 800px           !default;
$container-max-width-sm-px: 1000px          !default;
$container-max-width-md-px: 1200px          !default;
$container-max-width-px:    1400px          !default;
$container-max-width-lg-px: 1600px          !default;
$container-max-width-xs:    rem($container-max-width-xs-px);
$container-max-width-sm:    rem($container-max-width-sm-px);
$container-max-width-md:    rem($container-max-width-md-px);
$container-max-width:       rem($container-max-width-px);
$container-max-width-lg:    rem($container-max-width-lg-px);
$container-padding-v:       0               !default;
$container-padding-h:       rem(20px)       !default;

// Sections
// ==================================================
$section-padding: rem(20px) 0               !default;

// Buttons
// ==================================================
$button-background-color: $off-black        !default;
$button-background-color-hover: shade($button-background-color, 20%) !default;
$button-color:       find-color-invert($button-background-color) !default;
$button-color-hover: find-color-invert($button-background-color-hover) !default;
$button-font-size:   rem($base-font-size)   !default;
$button-font-weight: $primary-font-weight   !default;
$button-padding:     rem(10px) rem(20px)    !default;
$button-border:      none                   !default;
$button-border-radius: none                 !default;

// Forms
// ==================================================
$form-background-color: $off-white          !default;
$form-box-shadow: inset 0 rem(1px) rem(3px) rgba($black, 0.05)  !default;
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba($blue, 0.75)  !default;

// Horizontal Rule
// ==================================================
$hr-width:      100%                         !default;
$hr-height:     rem(1px)                     !default;
$hr-background: $black                       !default;

// Misc
// ==================================================
$base-z-index: 0                            !default;