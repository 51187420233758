.header-main {
  background: $blue-purple;
  padding: rem(90px) 0 rem(42px);
  color: $white;

  @include phone {
    padding: rem(70px) 0 rem(32px);
  }

  .hashtag {
    position: absolute;
    display: flex;
    align-items: center;
    background: $blue-purple;
    top: 0 - rem(21px);
    right: 0;
    height: rem(54px);
    padding: 0 rem(80px) 0 rem(20px);
    font-weight: 900;
    font-size: rem(34px);
    text-transform: uppercase;
    color: $white;

    @include phone {
      top: 0 - rem(11px);
      height: rem(34px);
      padding: 0 rem(20px);
      font-size: rem(18px);
    }
  }

  .page-title {
    font-family: $font-quanta-east;
    font-size: rem(60px);
    font-weight: normal;
    width: 50%;
    margin-bottom: rem(20px);

    @include phone {
      width: 100%;
      font-size: rem(36px);
    }
  }

  .subheading {
    font-size: rem(25px);
    font-weight: 900;
    width: 75%;
    line-height: 1.5;

    @include phone {
      font-size: rem(20px);
    }
  }
}
