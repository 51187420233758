.apply {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: rem(45px);
  padding-bottom: rem(45px);

  .apply-heading {
    font-weight: 500;
    font-size: rem(31px);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
  }

  .apply-paragraph {
    font-weight: 300;
    font-size: rem(21px);
    line-height: rem(30px);
    text-align: center;
    max-width: rem(840px);

    ul {
      list-style-type: disc;
      list-style-position: inside;

      li {
        text-align: center;
        margin-top: rem(10px);
      }
    }
  }

  .apply-links-heading {
    font-weight: 500;
    font-size: rem(31px);
    line-height: 1;
    text-transform: uppercase;
    margin-top: rem(50px);
    margin-bottom: rem(26px);
  }

  .apply-links-paragraph {
    font-weight: 300;
    font-size: rem(21px);
    line-height: rem(30px);
    text-align: center;
    max-width: rem(910px);
  }

  .apply-links {
    width: calc(100% + #{rem(4px)});
    margin-top: rem(40px);
    margin-left: 0 - rem(2px);
    margin-right: 0 - rem(2px);

    .apply-link-col {
      padding: 0 rem(2px);

      @include lg-tablet-max {
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: rem(4px);
      }

      @include phone {
        flex-basis: 100%;
        max-width: 100%;
      }

      .apply-link {
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem(55px) rem(35px) rem(35px);
        height: 100%;

        &.blue {
          background-color: $blue;
        }

        &.purple {
          background-color: $purple;
        }

        &.dark-blue {
          background-color: $dark-blue;
        }

        .apply-link-heading {
          font-family: $font-quanta-east;
          font-weight: normal;
          font-size: rem(31px);
          line-height: 1;
          color: $white;
          text-align: center;
        }

        .apply-link-subheading {
          font-weight: 500;
          font-size: rem(25px);
          line-height: rem(37.5px);
          max-width: rem(188px);
          text-align: center;
          margin-top: rem(20px);
          margin-bottom: 0;
        }

        .apply-link-button {
          width: 100%;
          max-width: rem(232px);

          // @include lg-tablet-max {
          //   height: rem(50px);
          //   line-height: rem(50px);
          //   padding: 0 rem(28px) 0 rem(20px);
          //   font-size: rem(16px);
          // }
        }
      }
    }
  }
}
