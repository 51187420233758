.blue-purple-overlay {
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 66vw;
    max-width: rem(600px);
  }

  &:before {
    left: 0;
    background: linear-gradient(130deg, rgba($blue,0.25) 0%, rgba($blue,0) 33%, rgba($blue,0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(320deg, rgba($purple,0.25) 0%, rgba($purple,0) 33%, rgba($purple,0) 100%);
  }
}

.gradient-border-top,
.gradient-border {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: $purple-blue;
    left: 0;
    right: 0;
  }
}

.gradient-border-top {
  &:before {
    top: 0;
    height: rem(12px);
  }
}

.gradient-border {
  &:before {
    bottom: 0;
    height: rem(8px);
  }
}

.line {
  margin-top: rem(15px);
  height: rem(1px);
  background-color: $grey;
}

.image-sqaure {
  @include full-background;
  @include responsive-ratio(1, 1, true);

  background-size: 100%;
  transition: $base-transition;

  &:hover {
    background-size: 102%;
  }
}

hr {
  position: relative;
  margin: rem(26px) auto;
  max-width: rem(306px);
  height: rem(9px);
  background: $purple-blue;

  @include phone {
    margin: rem(21px) auto;
  }
}

.btn-link,
.btn-link:visited {
  margin-top: rem(30px);
  display: inline-block;
  height: rem(54px);
  line-height: rem(54px);
  background-color: $purple;
  padding: 0 rem(30px) 0 rem(22px);
  font-weight: 900;
  font-size: rem(18px);
  text-transform: uppercase;
  color: $white;
  z-index: 1;
  transition: $base-transition;
  white-space: nowrap;

  @include phone {
    height: rem(50px);
    line-height: rem(50px);
    padding: 0 rem(28px) 0 rem(20px);
    font-size: rem(16px);
  }

  &:before {
    content: '> ';
  }

  &:hover {
    background-color: lighten($purple, 10%);
    color: $white;
  }

  &.blue {
    background-color: $blue;

    &:hover {
      background-color: lighten($blue, 10%);
    }
  }

  &.dark-blue {
    background-color: $dark-blue;

    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }
}

.show-more,
.show-more:visited {
  margin: rem(30px) auto 0;
  display: block;
  height: rem(54px);
  line-height: rem(54px);
  background-color: $purple;
  padding: 0 rem(30px) 0 rem(22px);
  font-weight: 900;
  font-size: rem(18px);
  text-transform: uppercase;
  color: $white;

  @include phone {
    height: rem(50px);
    line-height: rem(50px);
    padding: 0 rem(28px) 0 rem(20px);
    font-size: rem(16px);
  }

  &:hover {
    background-color: lighten($purple, 10%);
    color: $white;
  }

  &.blue {
    background-color: $blue;

    &:hover {
      background-color: lighten($blue, 10%);
    }
  }

  &.dark-blue {
    background-color: $dark-blue;

    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }
}

.nav-search {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  align-items: center;
  border-bottom: rem(1px) solid $light-grey;

  .icon-search {
    height: rem(100px);
    line-height: rem(100px);
    font-size: rem(50px);
    margin-right: rem(10px);

    @include lg-tablet-max {
      height: rem(50px);
      line-height: rem(50px);
      font-size: rem(25px);
    }

    @include phone {
      height: rem(32px);
      line-height: rem(32px);
      font-size: rem(16px);
    }
  }

  .search {
    margin-bottom: 0;
    font-weight: 100;
    font-size: rem(50px);
    height: rem(100px);
    background-color: $white;
    border: none;
    box-shadow: none;

    @include placeholder {
      font-weight: 100;
      font-size: rem(50px);
      color: $light-grey2;
    }

    @include lg-tablet-max {
      font-size: rem(25px);
      height: rem(50px);

      @include placeholder {
        font-size: rem(25px);
      }
    }

    @include phone {
      font-size: rem(16px);
      height: rem(32px);

      @include placeholder {
        font-size: rem(16px);
      }
    }
  }
}

.flickity-page-dots {
  bottom: rem(-36px);

  .dot {
    background: $purple;
    width: rem(17px);
    height: rem(17px);
    margin: 0 rem(6px);
    opacity: 0.4;
  }
}
