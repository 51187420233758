.image {
  padding-top: rem(26px);
  padding-bottom: rem(60px);

  @include phone {
    padding-top: rem(16px);
    padding-bottom: rem(40px);
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
  }
}
