.contact-details {
  padding-top: rem(60px);
  padding-bottom: rem(60px);

  @include phone {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
  }

  .contact-links {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(45px);

    @include phone {
      margin-bottom: rem(25px);
      flex-direction: column;
      align-items: center;
    }

    .contact-link-left {
      margin-top: 0;

      @include phone {
        margin-bottom: rem(20px);
      }

      +.contact-link-right {
        @include tablet {
          margin-left: rem(34px);
        }
      }
    }

    .contact-link-right {
      margin-top: 0;
    }
  }

  .contact-details-list {
    padding-left: 0;
    padding-bottom: rem(50px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .contact-site-name {
    font-weight: 500;
    font-size: rem(22.5px);
    line-height: rem(34px);
    margin-bottom: 0;

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .contact-address {
    font-weight: 300;
    font-size: rem(22.5px);
    line-height: rem(34px);
    color: $grey;
    margin-bottom: 0;

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .contact-subheading {
    font-weight: 300;
    font-size: rem(22.5px);
    line-height: rem(34px);
    margin-bottom: rem(20px);

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .contact-link,
  .contact-link:visited {
    font-weight: 500;
    font-size: rem(22.5px);
    line-height: rem(34px);
    margin-bottom: 0;
    color: $blue-accent;

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }

    &:hover {
      color: lighten($blue-accent, 10%);
    }
  }

  hr {
    margin-left: 0;
  }

  .contact-map {
    @include full-background;

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
      height: calc(100vw - #{$container-padding-h * 2});
    }
  }

  .contact-details-subheading {
    font-weight: 500;
    font-size: rem(22.5px);
    line-height: rem(34px);
    margin-top: rem(26px);
    margin-bottom: rem(20px);

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .contact-details-paragraph {
    font-weight: 300;
    font-size: rem(22.5px);
    line-height: rem(34px);

    @include phone {
      font-size: rem(16px);
      line-height: 1.3;
    }
  }
}
