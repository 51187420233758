.full-image-overlay-text {
  @include full-background;
  position: relative;
  color: $white;
  padding-top: rem(100px);
  padding-bottom: rem(85px);

  @include phone {
    padding-top: rem(80px);
    padding-bottom: rem(65px);
  }

  &:before {
    @include pseudo;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $blue-overlay;
  }

  &:after {
    @include pseudo;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem(9px);
    background: $purple-blue;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .overlay-heading {
    font-family: $font-quanta-east;
    font-weight: normal;
    font-size: rem(64px);
    color: $white;
    text-align: center;

    @include tablet-max {
      font-size: rem(42px);
    }

    @include phone {
      font-size: rem(36px);
    }
  }

  .overlay-text {
    margin-top: rem(60px);
    max-width: rem(700px);
    font-weight: 300;
    font-size: rem(24px);
    line-height: rem(35px);
    text-align: center;

    @include phone {
      font-size: rem(16px);
      line-height: 1.4;
    }
  }

  .text-block-heading-link {
    margin-top: rem(50px);
  }
}
