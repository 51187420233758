.values {
  padding-top: rem(60px);
  padding-bottom: 0;
  background-color: $blue;
  color: $white;

  @include phone {
    padding-top: rem(40px);
  }

  >.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .values-heading {
    font-family: $font-quanta-east;
    font-weight: normal;
    font-size: rem(62.5px);
    line-height: 1.2;
    text-align: center;
    color: $white;

    @include phone {
      font-size: rem(36px);
    }
  }

  .values-paragraph {
    font-weight: 300;
    font-size: rem(23px);
    line-height: rem(35px);
    text-align: center;
    margin-top: rem(40px);

    @include phone {
      font-size: rem(16px);
      line-height: 1.4;
      margin-top: rem(20px);
    }
  }

  .values-link {
    display: inline-block;
    margin: rem(30px) auto rem(45px);
  }

  .values-slider-container {
    background: $dark-blue-purple;
    height: rem(156px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include phone {
      height: rem(120px);
    }

    .container {
      width: 100%;
    }

    .values-slider {
      width: 100%;
      margin-bottom: 0;

      .values-slide {
        height: rem(80px);
        width: 100%;
        display: inline-flex!important;
        align-items: center;
        justify-content: center;
        font-family: $font-quanta-east;
        font-weight: normal;
        font-size: rem(45.5px);
        line-height: 1.2;
        text-align: center;
        color: $white;

        @include phone {
          height: rem(50px);
          font-size: rem(20px);
        }
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(68px);
        line-height: 1;
        cursor: pointer;
        z-index: 5;

        @include phone {
          font-size: rem(42px);
        }

        &:before {
          margin: 0;
          width: rem(39px);
          opacity: 0.4;

          @include phone {
            width: rem(25px);
          }
        }

        &.icon-angle-left {
          left: 0;
        }

        &.icon-angle-right {
          right: 0;
        }
      }

      .slick-dots {
        bottom: rem(-20px);

        li {
          width: rem(12px);
          height: rem(12px);
          margin: 0 rem(2.5px);
          border-radius: 50%;

          button {
            width: rem(12px);
            height: rem(12px);
            padding: 0;
            border-radius: 50%;

            &:before {
              content: '';
              width: rem(12px);
              height: rem(12px);
              font-size: rem(12px);
              line-height: 1;
              background-color: $white;
              border-radius: 50%;
              opacity: 0.5;
              transition: $base-transition;
            }
          }

          &.slick-active {

            button:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
