.quotes {
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  .quotes-heading {
    font-family: $font-museo-sans;
    font-weight: 500;
    font-size: rem(31px);
    text-transform: uppercase;
    text-align: center;

    @include phone {
      font-size: rem(26px);
    }
  }

  .quotes-slider {
    display: block;
    width: calc(100vw - #{$container-padding-h * 2)});
    max-width: rem(700px);
    margin: rem(20px) auto;

    @include phone {
      max-width: calc(100vw - #{$container-padding-h * 2)});
    }

    .quote-slide {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100vw - #{$container-padding-h * 2)});
      max-width: rem(700px);
      padding-bottom: rem(10px);

      @include phone {
        max-width: calc(100vw - #{$container-padding-h * 2)});
      }

      .quote-text {
        font-weight: 500;
        font-size: rem(36px);
        line-height: 1.2;
        color: $dark-blue;
        text-align: center;
        margin-bottom: rem(45px);

        @include phone {
          font-size: rem(16px);
        }
      }

      .quote-name,
      .quote-date {
        font-weight: 500;
        font-style: italic;
        font-size: rem(18px);
        line-height: 0.8;
        text-align: center;

        @include phone {
          font-size: rem(16px);
        }
      }
    }
  }
}
