.intro {
  padding-top: rem(60px);
  padding-bottom: rem(60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include phone {
    padding-top: rem(50px);
    padding-bottom: rem(50px);
  };

  .intro-heading {
    font-family: $font-museo-sans;
    font-weight: 500;
    font-size: rem(31px);
    text-transform: uppercase;
    text-align: center;

    @include phone {
      font-size: rem(26px);
    }
  }

  .intro-paragraph {
    font-size: rem(21px);
    font-weight: 300;
    text-align: center;
    max-width: rem(840px);

    @include phone {
      font-size: rem(16px);
    }
  }

  .intro-links {

    @include phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .intro-link-right {
      @include tablet {
        margin-left: rem(64px);
      }
    }
  }
}
