footer {
  background-color: $black;
  padding-top: rem(80px);

  @include phone {
    padding-top: rem(60px);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include tablet-max {
      flex-basis: 100%!important;
      max-width: 100%!important;
    }
  }

  .footer-heading {
    font-weight: 900;
    font-size: rem(34px);
    line-height: 1.4;
    color: $white;
    text-transform: uppercase;
    position: relative;
    padding-bottom: rem(9px);
    display: inline-block;

    &:after {
      @include pseudo;
      bottom: 0;
      left: 0;
      right: 0;
      height: rem(9px);
      background: $purple-blue;
    }
  }

  .footer-subheading {
    margin-top: rem(20px);
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;
    color: $dark-blue;
    margin-bottom: 0;
  }

  .footer-address {
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .footer-link {
    font-weight: 500;
    color: $white;
    line-height: 1.4;

    &:hover,
    &:focus {
      color: darken($white, 10%);
    }

    &:last-child {
      @include tablet-max {
        margin-bottom: rem(30px);
      }
    }
  }

  .footer-col-badges {
    overflow: hidden;

    @include tablet-max {
      flex-basis: 100%!important;
      max-width: 100%!important;
    }
  }

  .footer-badges {
    margin: rem(20px) (($grid-gutter * 1.5) * -1);

    @include tablet-max {
      margin: rem(20px) ($container-padding-h * -1);
    }

    @include phone {
      margin: rem(20px) (($grid-gutter * 0.66) * -1);
    }

    .footer-badge {
      padding: 0 ($grid-gutter * 1.5);

      @include tablet-max {
        padding: 0 $container-padding-h;
      }

      @include phone {
        padding: 0 ($grid-gutter * 0.66);
      }

      .badge-image {
        height: rem(60px);
        max-height: rem(60px);
        display: flex;
        align-items: center;
        margin-bottom: rem(20px);

        img {
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .badge-description {
        font-weight: 300;
        font-size: rem(12px);
        line-height: rem(16px);
      }

      .badge-link {
        font-weight: 900;
        font-size: rem(16.5px);
        line-height: rem(25px);
        color: $white;
        text-transform: uppercase;

        @include phone {
          font-weight: 500;
          font-size: rem(10px);
          line-height: rem(12px);
        }

        &:before {
          content: '> ';
        }

        &:hover,
        &:focus {
          color: darken($white, 10%);
        }
      }
    }
  }

  .hash-tag {
    align-self: flex-end;
    font-weight: 900;
    font-size: rem(34px);
    text-transform: uppercase;

    @include phone {
      font-size: rem(22.75px);
    }
  }

  .copyright {
    align-self: flex-end;
    font-weight: 300;
    font-size: rem(12.5px);
    line-height: 1;
    color: $white;
    margin-top: rem(5px);
    text-align: right;

    @include phone {
      font-size: rem(12px);
      line-height: 1.4;
    }

    .copyright-link {
      font-weight: 300;
      font-size: rem(12.5px);
      line-height: 1;
      color: $white;

      @include phone {
        font-size: rem(12px);
        line-height: 1.4;
      }

      &:hover,
      &:focus {
        color: darken($white, 10%);
      }
    }
  }
}
