$socialgram-blue: #38b8cd;

$pale-blue:       #80cded;
$light-blue:      #4db8db;
$blue:            #019cdb;
$blue-accent:     #006699;
$dark-blue:       #0b5fb0;
$blue-promo:      #207fba;
$purple:          #942279;
$red:             #fb0404;

$off-white-blue:  #e5eff5;

$white:           #ffffff;
$light-grey:      #ebe8e8;
$light-grey2:     #a9a9a9;
$grey:            #4d4d4d;
$dark-grey:       #333;
$black:           #000;

$overlay-color:   rgba($black, 0.6);

$blue-overlay:    rgba(#0a2693, 0.88);

$blue-purple:     linear-gradient(90deg, rgba($blue, 1) 0%, rgba($purple ,1) 100%);
$purple-blue:     linear-gradient(90deg, rgba($purple ,1) 0%, rgba($blue, 1) 100%);

$purple-blue-promo:     linear-gradient(90deg, rgba($purple ,1) 0%, rgba($blue-promo, 1) 100%);

$dark-blue-purple:     linear-gradient(90deg, rgba($dark-blue, 1) 0%, rgba($purple ,1) 100%);
$purple-dark-blue:     linear-gradient(90deg, rgba($purple ,1) 0%, rgba($dark-blue, 1) 100%);



$blue-purple-solid: linear-gradient(90deg, rgba($blue, 1) 0%, rgba($blue, 1) 50%, rgba($purple, 1) 50%, rgba($purple ,1) 100%);
$purple-blue-solid: linear-gradient(90deg, rgba($purple, 1) 0%, rgba($purple, 1) 50%, rgba($blue, 1) 50%, rgba($blue ,1) 100%);

$blue-dark-blue-solid: linear-gradient(90deg, rgba($blue, 1) 0%, rgba($blue, 1) 50%, rgba($dark-blue, 1) 50%, rgba($dark-blue ,1) 100%);
$dark-blue-blue-solid: linear-gradient(90deg, rgba($dark-blue, 1) 0%, rgba($dark-blue, 1) 50%, rgba($blue, 1) 50%, rgba($blue ,1) 100%);

$base-spacing: rem(15px);


$primary-font-family: $font-museo-sans;
//$primary-font-weight: 400;

$secondary-font-family: $font-museo-sans;

$heading-font-family: $font-museo-sans;
$heading-font-color: $dark-grey;

$focus-outline:        none;
$focus-box-shadow:     none;


$container-max-width-xs-px: 890px;
$container-max-width-px: 1040px;



$section-padding: rem(30px) 0;


$form-box-shadow: none;
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba($blue, 0.75);


$hr-width: rem(546px);
$hr-height: rem(3px);
$hr-background: linear-gradient(to right, rgba($socialgram-blue, 0), rgba($socialgram-blue, 0.5), rgba($socialgram-blue, 0));
$hr-background2: linear-gradient(to right, rgba($socialgram-blue, 0), rgba($socialgram-blue, 1), rgba($socialgram-blue, 0));


$nav-bar-height: rem(73px);
$nav-bar-toggle-width: rem(70px);
$nav-menu-width: rem(410px);
