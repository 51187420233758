.results {
  padding-top: rem(60px);
  padding-bottom: rem(60px);


  .results-number {
    font-weight: 500;
    font-size: rem(21px);
    line-height: 1;
    margin-top: rem(40px);
  }

  .result {
    font-weight: 300;
    font-size: rem(21px);
    line-height: rem(30px);
    margin-top: rem(30px);

    &:not(:last-child) {
      margin-bottom: rem(30px);
    }
  }
}
