// Import any CDN fonts
// ==================================================

// https://fonts.google.com
// https://fonts.adobe.com/typekit

@import url("https://use.typekit.net/hoh6duq.css");
/*
Museo Sans: 100, 300, 500, 500 it, 900
Museo Sans Cond: 300
*/

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400&display=swap'); // 300, 300 italic, 400


// Include any hosted fonts
// ==================================================

@include font-file('Quanta East', '../font/QuantaEast', normal);

// Font stacks
// ==================================================

$font-quanta-east: 'Quanta East', sans-serif;
$font-museo-sans: museo-sans, sans-serif;
$font-museo-sans-condensed: museo-sans-condensed, sans-serif;