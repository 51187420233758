.listing-content {
  padding-top: rem(20px);
  padding-bottom: rem(80px);

  @include phone {
    padding-top: rem(15px);
    padding-bottom: rem(50px);
  }

  .listing-item {
    margin-top: rem(70px);
    margin-bottom: rem(40px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: rem(40px);
      margin-bottom: rem(10px);
    }

    .listing-image {
      position: relative;
      padding-bottom: rem(9px);
      @include full-background;
      @include responsive-ratio(1, 1);

      &:after {
        @include pseudo;
        bottom: 0;
        left: 0;
        right: 0;
        height: rem(9px);
        background: $purple-blue;
      }

      // .listing-date {
      //   position: absolute;
      //   top: rem(34px);
      //   left: 0;
      //   width: 100%;
      //   max-width: rem(388px);
      //   height: rem(54px);
      //   line-height: rem(54px);
      //   font-weight: 500;
      //   font-size: rem(25px);
      //   background: $blue-purple;
      //   color: $white;
      //   padding-left: rem(40px);
      //
      //   @include phone {
      //     height: rem(34px);
      //     line-height: rem(34px);
      //     font-size: rem(18px);
      //   }
      // }
    }

    .listing-heading {
      font-weight: 500;
      font-size: rem(31px);
      line-height: rem(48px);
      text-transform: uppercase;
      margin-top: rem(28px);

      @include phone {
        font-size: rem(22px);
        line-height: 1.4;
      }
    }

    .listing-paragraph {
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(30px);
      max-width: rem(430px);
      margin-top: rem(20px);

      @include phone {
        font-size: rem(16px);
        line-height: 1.3;
      }
    }
  }
}
