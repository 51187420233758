.stats {
  padding-top: rem(30px);
  padding-bottom: rem(49px);
  background-color: $dark-blue;
  color: $white;
  position: relative;

  @include phone {
    padding-top: rem(20px);
    padding-bottom: rem(39px);
  }

  &:after {
    @include pseudo;
    bottom: 0;
    left: 0;
    right: 0;
    height: rem(9px);
    background: $purple-blue;
  }

  .stat-heading {
    background: $purple-blue;
    height: rem(70px);
    margin-bottom: rem(45px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: rem(38px);
    text-transform: uppercase;
    color: $white;

    @include phone {
      height: rem(60px);
      font-size: rem(26px);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stat-stat {
    font-family: $font-quanta-east;
    font-weight: normal;
    font-size: rem(170px);
    line-height: 0.925;
    color: $white;

    @include phone {
      font-size: rem(70px);
    }

    &:after {
      content: '*';
      font-size: rem(44px);
      line-height: 1;
      vertical-align: top;

      @include phone {
        font-size: rem(30px);
      }
    }
  }

  .stat-subheading {
    max-width: rem(485px);
    font-weight: 900;
    font-size: rem(22.5px);
    text-align: center;
    color: $white;
    margin-top: rem(15px);

    @include phone {
      font-size: rem(18px);
    }
  }

  .stat-list-heading {
    max-width: rem(770px);
    font-weight: 900;
    font-size: rem(22.5px);
    line-height: rem(35px);
    text-align: center;
    color: $white;
    margin-top: rem(10px);

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .stat-list-subheading {
    font-weight: 500;
    font-size: rem(22.5px);
    line-height: rem(37.5px);
    text-align: center;
    color: $white;
    margin-top: rem(10px);

    @include phone {
      font-size: rem(18px);
      line-height: 1.3;
    }
  }

  .stat-list-bullets {
    list-style-type: disc;
    list-style-position: inside;

    .stat-list-bullet {
      font-weight: 500;
      font-size: rem(22.5px);
      line-height: rem(37.5px);
      text-align: center;
      margin-top: rem(10px);

      @include phone {
        font-size: rem(16px);
        line-height: 1.3;
      }
    }
  }

  .stat-note {
    font-weight: 500;
    font-style: italic;
    font-size: rem(16px);
    color: $white;
    text-align: center;
    margin-top: rem(25px);

    @include phone {
      font-size: rem(14px);
    }
  }
}
