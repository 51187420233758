.header-open-event-image {
  height: rem(600px);
  @include full-background;

  @include phone {
    height: 50vw;
  }
}

.header-open-event-main {
  margin-top: 0 - rem(120px);

  @include phone {
    margin-top: 0 - rem(30px);
  }

  .header-open-event-container {
    background: $blue-purple;
    color: $white;
    padding: rem(40px);

    @include phone {
      padding: rem(20px) rem(25px);
    }

    .header-open-event-header {
      font-family: $font-quanta-east;
      font-weight: normal;
      font-size: rem(60px);
      line-height: rem(73px);
      color: $white;

      @include phone {
        font-size: rem(36px);
        line-height: 1.3;
      }
    }

    .header-open-event-date {
      font-weight: 900;
      font-size: rem(25px);
      line-height: rem(28px);
      margin-top: rem(20px);

      @include phone {
        font-size: rem(18px);
        line-height: 1.3;
      }
    }

    .header-open-event-time {
      font-weight: 500;
      font-size: rem(25px);
      line-height: rem(28px);

      @include phone {
        font-size: rem(18px);
        line-height: 1.3;
      }
    }
  }
}
